///* ========================================================================
//   #COLORS
//   ======================================================================== */


$color-red:         #E20142;
$color-brand:       #ECAF03;

$color-blue:        #2d4485;
$color-blue-grey:   #A1AEC3;
$color-grey:        #9B9B9B;
$color-ui:          #DCDCDC;
$color-ui-light:    #F5F3F2;
$color-headings:    #4A4A4A;

$color-grey-light:  #A0A0A0;
$color-btn-primary: #004686;


$color-date-default:    $color-grey-light;

$color-block-blue:      $color-brand;