///* ========================================================================
//   #GLOBAL
//   ======================================================================== */
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

// Standardise some UI treatments.
$global-radius: 0px;
$global-transition: (1/3) + s !default;


$inuit-global-spacing-unit-factor-tiny:   0.25 !default;
$inuit-global-spacing-unit-factor-small:  0.5  !default;
$inuit-global-spacing-unit-factor-large:  2    !default;
$inuit-global-spacing-unit-factor-huge:   4    !default;


$inuit-wrapper-width: 1200px;

$page-width: $inuit-wrapper-width;
$page-header-desktop: 100px;
$page-header-mobile: 88px;


