/* ==========================================================================
   #ICONS
   ========================================================================== */

.icon {
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    background-position: center;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
}

.icon-expertism-preview {
    margin-left: 50%;
    transform: translate(-50%);
    height: 78px;
}