/*  SECTIONS  */
.section {
    clear: both;
    padding: 0px;
    margin: 0px;
}

/*  COLUMN SETUP  */
.col {
    display: block;
    float:left;
    margin: 1% 0 1% 1.6%;
    position: relative;
}
.col:first-child { margin-left: 0; }

/*  GROUPING  */
.group:before,
.group:after { content:""; display:table; }
.group:after { clear:both;}
.group { zoom:1; /* For IE 6/7 */ }

/*  GRID OF TWELVE  */
.span_12_of_12 {
    width: 100%;
}

.span_11_of_12 {
    width: 91.53%;
}
.span_10_of_12 {
    width: 83.06%;
}

.span_9_of_12 {
    width: 74.6%;
}

.span_8_of_12 {
    width: 66.13%;
}

.span_7_of_12 {
    width: 57.66%;
}

.span_6_of_12 {
    width: 49.2%;
}

.span_5_of_12 {
    width: 40.73%;
}

.span_4_of_12 {
    width: 32.26%;
    margin-bottom: 33px;
}

.span_3_of_12 {
    width: 23.8%;
}

.span_2_of_12 {
    width: 15.33%;
}

.span_1_of_12 {
    width: 6.866%;
}

/*  GRID OF THREE  */


.col {  margin: 1% 0 calc(1% + 25px) 1.6%; }
/*  GO FULL WIDTH BELOW 480 PIXELS */
@include mq($until: tablet){
    .span_1_of_12, .span_2_of_12, .span_3_of_12, .span_4_of_12, .span_5_of_12, .span_6_of_12, .span_7_of_12, .span_8_of_12, .span_9_of_12, .span_10_of_12, .span_11_of_12, .span_12_of_12 {
        width: 100%;
    }
    .span_3_of_3, .span_2_of_3, .span_1_of_3 { width: 100%; }
    .col {  margin: 1% 0 calc(1% + 25px) 0%; }

}

@include mq($from: tablet, $until: desktop){
    .span_3_of_3, .span_2_of_3, .span_1_of_3 { width: 49.2%; }
    .span_1_of_3:nth-child(2n+1) {
        clear:both;
        margin-left: 0;
    }
}
@include mq($from: desktop) {
    .span_1_of_3:nth-child(3n+1) {
        clear: both;
        margin-left: 0;
    }
    .span_3_of_3 { width: 100%; }
    .span_2_of_3 { width: 66.13%; }
    .span_1_of_3 { width: 32.26%; }
}

/*  GO FULL WIDTH BELOW 480 PIXELS */





/*  PUSH CREATES EMPTY SPACES */
.push {
    visibility: hidden;
}

@media only screen and (max-width: 480px)
{

    .push {
        display: none;
    }

}

.span_4_of_12:nth-child(3n+1) {
    clear:both;
    margin-left: 0;
}
.span_12_of_12:nth-child(1n+1) {
    clear:both;
    margin-left: 0;
}

.span_5_of_5 {
    width: 100%;
}
.span_4_of_5 {
    width: 79.68%;
}
.span_3_of_5 {
    width: 59.36%;
}
.span_2_of_5 {
    width: 39.04%;
}
.span_1_of_5 {
    width: 18.72%;
    text-align: center;
}

.span_1_of_4 {
    width: 25%;
    text-align: center;
}