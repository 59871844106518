/*------------------------------------*\
    #TYPEFACE
\*------------------------------------*/

@font-face {
  font-family: 'blogger-bold';
  src: url('../../fonts/Blogger_Sans-Bold-webfont.woff') format('woff');
}
@font-face {
  font-family: 'blogger-regular';
  src: url('../../fonts/Blogger_Sans-webfont.woff') format('woff');
}
@font-face {
  font-family: 'blogger-medium';
  src: url('../../fonts/Blogger_Sans-Medium-webfont.woff') format('woff');
}