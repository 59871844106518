/* ==========================================================================
   #LOGO
   ========================================================================== */

.site-logo {
    display: block;
    @include font-size(15px, 19px);
    width: 165px;
    border-left: 1px solid black;
    padding-left: 10px;
    text-transform: uppercase;
    a {
        color: inherit;
    }
    @include mq($from: tablet) {
        width: 208px;
        height: 92px;
        @include font-size(19px, 23px);
    }
}
