/* ==========================================================================
   #FILTERS
   ========================================================================== */


.filter {
    @include font-size(13px, 15px);
    color: $color-headings;

    li {
        float: left;
        margin-right: 16px;
        &:last-child {
            margin-right: 0;
        }
        a {
            color: $color-headings;
            @include attention() {
                color: $color-btn-primary;
                .filter__item {
                    background-color: #ccc;
                }

            }

        }
    }
}

.filter__item {
    float: left;
    width: 15px;
    height: 15px;
    box-shadow: inset 0 0 0 1px #000;
    margin-right: 4px;
    background-color: #fff;

}


.filter__item--active {
    background-color: $color-blue;
    box-shadow: none;
}
