/* ==========================================================================
   #CONTENT
   ========================================================================== */
.home-text {
    max-width: 778px;
    margin: 0 auto;
    span{
        font-family: 'blogger-bold', sans-serif;
        @include font-size(43px);
        color: white;
    }
}

.home-tagline {
    margin: 1rem 0;
    font-size: 1rem;
}

.button-wrapper {
    display: flex;
    justify-content: center;
}

.spacer {
    height: 300px;
    @include mq($from: desktop){
        height:50vh;

    }
}

.page__intro{
    text-align: center;
    max-width: 789px;
    margin: 0 auto;
    @include font-size(23px, 27px);
    font-family: "blogger-regular", sans-serif;
    color: white;
    margin-bottom: $base-spacing-unit*2;
}

.article__header{
    margin-bottom: 36px!important;
}
.article__title{
    margin-bottom: 0px;
    @include mq($until: desktop){
        padding-right: 35px;
    }

}

.article__date {
    color: #949494;
    font-size: 13px;
}

.article__category{
    margin-right: 25px;
}

.article__signature{
    margin-top: 20px;
    margin-bottom: 70px;
    max-width: 233px;
}

.article__nav {
    position: absolute;
    color: white;
    text-transform: uppercase;
    bottom: -40px;

    &-next {
        right: 0px;
    }
    &-prev {
        left: 0px;
    }
}

blockquote{
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    font-size: 24px;
    padding-top: 21px;
    padding-bottom: 24px;
    text-align: center;
}




.main-content {
    margin-bottom: $base-spacing-unit*2;
}

.review{
    margin-bottom: 48px;
    &__client-name{
        @include font-size(24px,27px);
        color: $color-brand;
    }
    &__client-function{
        @include font-size(18px);
        margin-bottom: 21px;
    }
    &__client-review{
        max-width: 502px;
        margin: 0 auto;
    }
}

.contact-data{

    &__link {
        display: flex;
        align-items: flex-start;
        gap: 5px;
    }
    &__title{
        font-family: 'blogger-bold', sans-serif;
        color: $color-brand;
        margin-top: 90px;
        margin-bottom: 25px;
    }
    &__icon{
        width: 27px;
    }
}

.success-text{
    max-width: 800px;
    margin: 20px auto;
}