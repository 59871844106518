/* ==========================================================================
   #PAGINATION
   ========================================================================== */

.c-pagination {
    @include mq($from: tablet) {
        float: right;
    }

    list-style: none;
    margin: 0;
    padding: 0;

}

.pagination {
    float: right;
    margin-top: $half-spacing-unit;
    @include font-size(14px);
    a {
        padding: 2px;

    }

    .current {
        color: $color-blue;
    }
}