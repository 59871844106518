/* ==========================================================================
   BEELDR
   ========================================================================== */

/**
 *
 * http://beeldr.nl
 */

/**
 * This is an example inuitcss manifest file. DO NOT MODIFY THIS FILE DIRECTLY.
 * Instead, copy and paste it into your own CSS directory and make additions,
 * extensions, and modifications there.
 */

/**
 * CONTENTS
 *
 * SETTINGS
 * Config...............Project-level configuration and feature switches.
 * Core.................inuitcss’ core and setup settings.
 * Global...............Project-wide variables and settings.
 *
 * TOOLS
 * Rem..................Tools for converting pixel inputs into their rem
 *                      equivalents.
 * Font-size............A mixin which guarantees baseline-friendly line-heights.
 * Clearfix.............Micro clearfix mixin.
 * Hidden...............Mixin for hiding elements.
 * Sass MQ..............inuitcss’ default media query manager.
 *
 * GENERIC
 * Box-sizing...........Better default `box-sizing`.
 * Normalize.css........A level playing field using @necolas’ Normalize.css.
 * Reset................A tiny reset to complement Normalize.css.
 * Shared...............Sensibly and tersely share some global commonalities
 *                      (particularly useful when managing vertical rhythm).
 *
 * ELEMENTS
 * Page.................Set up our document’s default `font-size` and
 *                      `line-height`.
 * Headings.............Very minimal (i.e. only font-size information) for
 *                      headings 1 through 6.
 * Images...............Base image styles.
 * Tables...............Simple table styles.
 *
 * OBJECTS
 * Wrapper..............Page constraint object.
 * Layout...............Generic layout module.
 * Media................Image- and text-like content side by side. The
 *                      poster-child of OOCSS.
 * Flag.................Table-layout-based advancement on the Media object.
 * List-bare............Lists with no bullets or indents.
 * List-inline..........A list whose items all site in a line.
 * Box..................Simple boxing abstraction.
 * Block................Image-on-top-of-text object.
 * Ratio................A container for maintaining aspect ratio of content.
 * Crop.................Provide a cropping context for media (images, etc.).
 * Tables...............Classes for manipulating `table`s.
 * Pack.................Pack items into available horizontal space.
 *
 * COMPONENTS
 * Buttons..............An example button component, and how it fits into the
 *                      inuitcss framework.
 *
 * UTILITIES
 * Clearfix.............Bind our clearfix onto a utility class.
 * Widths...............Simple width helper classes.
 * Headings.............Reassigning our heading styles to helper classes.
 * Spacing..............Nudge bits of the DOM around with these spacing
 *                      classes.
 * Print................Reset-like styles taken from the HTML5 Boilerplate.
 * Hide.................Helper classes to hide content
 */

// SETTINGS
@import "settings/settings.config";
@import "node_modules/inuitcss/settings/settings.core";
@import "settings/settings.global";
@import "settings/settings.grid";
@import "settings/settings.color";
@import "settings/settings.animations";

// TOOLS
@import "node_modules/inuitcss/tools/tools.font-size";
@import "node_modules/inuitcss/tools/tools.clearfix";
@import "node_modules/sass-mq/mq";
@import "tools/tools.aliases";
@import "tools/tools.mixins";

// GENERIC
@import "node_modules/inuitcss/generic/generic.box-sizing";
@import "node_modules/inuitcss/generic/generic.normalize";
@import "node_modules/inuitcss/generic/generic.shared";

// ELEMENTS
@import "node_modules/inuitcss/elements/elements.page";
@import "node_modules/inuitcss/elements/elements.headings";

// OBJECTS
@import "node_modules/inuitcss/objects/objects.layout";
@import "node_modules/inuitcss/objects/objects.media";
@import "node_modules/inuitcss/objects/objects.flag";
@import "node_modules/inuitcss/objects/objects.list-bare";
@import "node_modules/inuitcss/objects/objects.list-inline";
@import "node_modules/inuitcss/objects/objects.box";
@import "node_modules/inuitcss/objects/objects.block";
@import "node_modules/inuitcss/objects/objects.table";

// COMPONENTS
@import "components/components.buttons";
@import "components/components.page-head";
@import "components/components.page-foot";

// UTILITIES
@import "node_modules/inuitcss/utilities/utilities.widths";
@import "node_modules/inuitcss/utilities/utilities.headings";
@import "node_modules/inuitcss/utilities/utilities.spacings";

@import "node_modules/sass-mq/mq";

@import "tools/tools.aliases";
@import "tools/tools.mixins";


// BASE
@import "base/base.typeface";
@import "base/base.page";
@import "base/base.paragraphs";
@import "base/base.links";





@import "elements/elements.color";
@import "elements/elements.headings";
@import "elements/elements.images";
@import "elements/elements.table";

@import "objects/objects.wrapper";
@import "objects/objects.box";
@import "objects/objects.grid";
@import "objects/objects.media";




// @import "../bower_components/css-hamburgers/_sass/hamburgers/hamburgers";

// COMPONENTS
@import "components/components.buttons";
@import "components/components.page-head";
@import "components/components.page-foot";
@import "components/components.nav";
@import "components/components.layouts";
@import "components/components.bands";
@import "components/components.masthead";
@import "components/components.logo";
@import "components/components.list";
@import "components/components.block";
@import "components/components.box";
@import "components/components.pagination";
@import "components/components.breadcrumbs";
@import "components/components.content";
@import "components/components.icons";
@import "components/components.lead";
@import "components/components.meta";
@import "components/components.search";
@import "components/components.filter";
@import "components/components.forms";
@import "components/components.slider";
@import "components/components.clients";





// UTILITIES
// @import "../bower_components/inuitcss/utilities/utilities.clearfix";

$inuit-offsets: true;
//@import "../bower_components/inuitcss/utilities/utilities.widths";
// @import "../bower_components/inuitcss/utilities/utilities.headings";
//@import "../bower_components/inuitcss/utilities/utilities.spacing";
// @import "../bower_components/inuitcss/utilities/utilities.print";
// @import "../bower_components/inuitcss/utilities/utilities.hide";
@import "utilties/utilties.helpers";


