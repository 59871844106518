/*------------------------------------*\
    #ELEMENTS
\*------------------------------------*/

.color-red {
    color: $color-red;
}

.color-white {
    color: white;
}