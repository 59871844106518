/*------------------------------------*\
    #TABLE
\*------------------------------------*/

$inuit-table-border-color: $color-ui;
$inuit-table-border-width: 1px;
$inuit-table-border-style: solid;







    /**
     * Add borders only to `table`’s rows.
     */
    table {

        &,
        th,
        td {
            border: 0 $inuit-table-border-style $inuit-table-border-color;
            vertical-align: top;
        }

    }

    table {
        border-top-width: $inuit-table-border-width;

        th,
        td {
            border-bottom-width: $inuit-table-border-width;
            padding: 5px 8px;
        }

    }






