/* ==========================================================================
   #BLOCK
   ========================================================================== */

.block {
    background-color: white;
    box-shadow: 5px 5px 19px rgba(0,0,0,0.29);
    position: relative;
    h1{
        color: $color-brand;
    }
    &__article{
        padding:  20px $base-spacing-unit;

        @include mq($from: tablet, $until: desktop){
            padding: 16px 50px 75px ;
        }
        @include mq($from: desktop){
            padding: 16px 100px 75px 100px;
        }


    }

    &-post-preview{
        padding: 34px 25px 9px;
        &:hover{
            transform: scale(1.05);
        }
        .post__title{
            @include font-size(23px, 27px);
            color: $color-brand;
            text-transform: uppercase;
            margin-bottom: 5px;
        }
        .post__categories{
            @include font-size(13px, 20px);
            color: #9B9B9B;
            margin-bottom: 10px;
        }
        .post__category{
            margin-right: 25px;

        }
        .post__date{
            @include font-size(13px);
            color: #949494;
            margin-bottom: 5px;
        }
        p{
            @include font-size(15px, 21px);
            color: #4A4A4A;
        }
        .post__link{
            position: absolute;
            bottom: -12px;
            z-index: 1;
            &:hover {
                transform: scale(1.1);
            }
        }
    }
    &--post-more-articles{
        margin-bottom: 40px;
    }
    &-sidebar {
        background-color: #FFCA0F;
        color: white;
        min-height: 357px;
        margin-top: 5px;
        margin-bottom:5px;
        padding: 34px 25px 9px;
        text-align: center;
        &__text{
            font-family: 'blogger-regular', sans-serif;
            @include font-size(20px);
            max-width: 215px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: 35px;
            margin-bottom: 31px;
            text-transform: uppercase;
        }
        input{
            width: 100%;
        }
    }
    &-expertism-preview{
        padding:34px 34px 1px;
        @include font-size(15px,21px);
        &__title{
            @include font-size(24px, 21px);
            color: $color-brand;
            text-transform: uppercase;
            margin-bottom: 41px;
            margin-top: 35px;
        }
        &:hover{
            transform: scale(1.05);
        }
    }
    &-subnav{
        padding:25px 13px;
        margin-bottom: 12px;
        padding: 25px 13px;
        display: flex;
        align-items: center;
        gap: 5px;

        &:hover{
            transform: scale(1.05);
        }
        &-icon-container{
            width: 20%;
            display: inline-block;
            @include mq($from: tablet){

                img {
                    width: 100%;
                }
            }
        }
        &-title{
            display: inline-block;
            @include font-size(18px);
            color: $color-brand;
            text-transform: uppercase;
            margin-left: 3%;
            width:70%;
        }
        &:first-of-type{

        }
    }
    &-client-list{
        padding:24px;

        @include mq($until: desktop){
            h2{
                @include font-size(20px);
                font-family: blogger-regular, sans-serif;
            }
            ul{
                list-style-type: none;
                margin-top: 20px;
                margin-left: 0px!important;
                li{
                    @include font-size(20px);
                    margin-bottom: 22px;

                }
            }
        }
        @include mq($from: desktop){
            padding:50px 40px;
            h2{
                @include font-size(36px, 50px);
                font-family: blogger-regular, sans-serif;
            }
            ul{
                list-style-type: none;
                margin-left: 0px;
                li{
                    @include font-size(24px);
                    margin-bottom: 31px;

                }
            }
        }

    }
    &-about{

        &__content{
            padding:26px 41px;
            @include mq($until: desktop){
                padding: 26px $base-spacing-unit;
            }

        }

        &__image {
            width: 100%;
        }
        &__name{
            @include font-size(24px);
        }
        &__function{
            @include font-size(18px);
        }
        h2{
            @include font-size(36px);
            color: $color-brand;
            font-family: blogger-regular, sans-serif;
        }


    }
}

$color: rgb(255,255,255);
$colorDark: darken($color, 10%) transparent;

.cornered {
    background: $color;
    position: relative;
    min-height: 10em;
    &--orange{
        background: #FFCA0F;
        &:after{
            border-color: #FFCA0F!important;
        }
        &:before {
            border-color: #ECAF03 transparent!important;
        }
    }

    &:after {
        content: "";
        position: absolute;
        bottom: -25px;
        left: 0;
        right: 25px;
        border-width: 13px;
        border-style: solid;
        border-color: white;

    }
    &:before {
        content: "";
        position: absolute;
        bottom: -25px;
        right: 0;
        border-width: 25px 25px 0 0;
        border-style: solid;
        border-color: darken(white, 10%) transparent;
    }


}

.cornered--top {
    background: $color;
    position: relative;
    min-height: 20em;
    &:after {
        content: "";
        position: absolute;
        top: -50px;
        left: 0;
        right: 50px;
        border-width: 2em;
        border-style: solid;
        border-color: white;

    }
    &:before {
        content: "";
        position: absolute;
        top: -50px;
        right: 0;
        border-width: 0 50px 50px 0  ;
        border-style: solid;
        border-color: darken(white, 10%) transparent;
    }
}

.cta-block{
    position: absolute;
    top: 110px;
    &:hover{
        img{
            box-shadow: none;
            transform: scale(0.95);
        }
    }
    @include mq($from: tablet, $until:desktop){
        top:60px;
    }
    img{
        transition-duration: 0.3s;
        width: 180px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
        border-radius: 9999px;
    }
    @include mq($from:desktop){
        top: 20vh;
        img{
            height: 20vh;
            width: 20vh;
        }
    }
}