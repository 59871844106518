/*------------------------------------*\
    #PAGE
\*------------------------------------*/

body {
    color: #000;
  position: relative;
    font-family: 'blogger-regular';
    @include font-size(16px, 28px);
  min-height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.body__background--gray{
        background-color: #F9F9F9;
}

.regular {
  margin-bottom: 170px;
}
.cornered-spacing{
  margin-top: 100px;
}
.cornered-spacing--bottom{
  margin-bottom: 100px!important;
}