/* ==========================================================================
   #BANDS
   ========================================================================== */

.band {
    padding-top: $base-spacing-unit*1.5;
    padding-bottom: $base-spacing-unit;
    &--orange{
        background-color: rgba(236, 175, 3, 0.85);

    }
}


