/* ==========================================================================
   #LAYOUT
   ========================================================================== */
header{
    margin-bottom: 50px;
}


.layout {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;

    @include mq($from: tablet) {
        flex-direction: row;
    }

}


.layout__full {
    width: 100%;
    display: block;
}

.layout__content {
    margin-bottom: $base-spacing-unit*3;

    @include mq($from: tablet) {
        order: 1;
        width: 100%;
        display: block;
        margin-bottom: $base-spacing-unit*1;
    }
}
