/*------------------------------------*\
    #HEADINGS
\*------------------------------------*/

h1 {
    @include inuit-font-size(46px, 50px);
    font-weight: normal;
    margin-bottom: $half-spacing-unit;
    @include mq($until: desktop){
        @include inuit-font-size(28px,30px);
    }
}


h2 {
    @include font-size(24px,27px);
    margin-bottom: $half-spacing-unit;
    color: $color-brand;
    font-weight: normal;
}

h3 {
    @include inuit-font-size(24px, 28px);
    margin-bottom: $half-spacing-unit;
    font-weight: normal;
}

.header__contact{
    margin-top: 110px;
    margin-bottom: 82px;
}

.page-title{
    position: relative;
    @include mq($from: 983px){
        position: absolute;
        -webkit-transform: rotate(90deg);
        -webkit-transform-origin: left top;
        -moz-transform: rotate(90deg);
        -moz-transform-origin: left top;
        -ms-transform: rotate(90deg);
        -ms-transform-origin: left top;
        -o-transform: rotate(90deg);
        -o-transform-origin: left top;
        transform: rotate(90deg);
        transform-origin: left top;
        top: 0;
        left: 95%;
        white-space: nowrap;
    }

}