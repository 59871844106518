/* ==========================================================================
   #PAGE-HEAD
   ========================================================================== */

.page-head {
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 1;
    width:100%;
    min-height: $page-header-mobile;

    @include mq($from: tablet) {
        position: fixed;
        min-height: $page-header-desktop;
        background-color: transparent;
    }
    .o-wrapper {
        position: relative;
        margin: 0 auto;
        padding-bottom: 5px;
        height:100%;
        @include mq($from: tablet) {
            padding-top: $base-spacing-unit*1.5;
        }
    }
}

.envelope{
    width: 100%;
    img{
        width:100%;
    }
    @include mq($until: tablet){
        display: none;
    }
}

.logo-mobile{
    text-align: center;
    max-width: 210px;
    overflow: visible;
    @include mq($from: tablet){
        display: none;
    }

}

.open-menu{
    text-align: center;
    @include mq($from: tablet){
        display: none;
    }
    &__text{
        display: block;
        font-family: blogger-bold;
        color: $color-brand;
    }
    &__arrow{
        display: inline-block;
    }
    &:hover {
        cursor: pointer;
    }
}


