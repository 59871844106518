/* ==========================================================================
   #MASTHEAD
   ========================================================================== */

@lost flexbox flex;
@lost gutter 0px;
@lost cycle none;

.header--offset {
    margin-top: 204px;
    @include mq($from:tablet){
        margin-top: 240px;
    }

}

.masthead {
    margin-top: 226px;
    margin-bottom: 10px;
    @include mq($until: tablet) {
        margin-top: 150px;
        margin-left: -$half-spacing-unit;
        margin-right: -$half-spacing-unit;
    }
    .subtitle{
        color: white;
        @include font-size(32px);
        text-align: center;
        @include mq($until: desktop){
            @include font-size(22px);;
        }
    }
}

.masthead--flex {
    lost-flex-container: row;
}

    .masthead__item {
        min-height: 200px;
    }

    .masthead__block {

        @include mq($from: tablet) {
            min-height: 100px;
        }
    }

    .masthead__image {
        width: 100%;
    }

    .masthead__content {
        padding: 30px 20px 20px 20px;
        @include font-size(18px, 22px);
        font-weight: 500;
        color: $color-headings;
        a {
            @include font-helvetica;
            @include font-size(13px, 18px);
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        @include mq($from: desktop-large) {
            padding: 40px 30px 20px 30px;
        }
    }

    .masthead__title {
        color: white;
        text-align: center;
        text-transform: uppercase;
    }
    .punchline {
        margin-top: 50px;
        @include font-size(30px, 27px);
        color: white;
        text-align: center;
        text-transform: uppercase;
        @include mq($until: desktop){
            @include font-size(28px);
        }
    }

    @include mq($from: tablet, $until: 1100px) {
        .masthead--body {
            display: none;
        }
    }
