/*------------------------------------*\
    #MIXINS
\*------------------------------------*/


@mixin font-helvetica {
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
}

@mixin font-open-sans-bold {
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-style: normal;
}



//DO THE CLEARFIX
@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}



// Apply ‘attention’ styles in one go.
@mixin attention($self: false) {

    @if($self != false) {

        &,
        &:hover,
        &:active,
        &:focus {
            @content;
        }

    }

    @else {

        &:hover,
        &:active,
        &:focus {
            @content;
        }

    }

}


