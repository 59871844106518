/*------------------------------------*\
    #LINKS
\*------------------------------------*/

a {
    text-decoration: none;
    color: #000000;
    transition: $global-transition;
    @include attention(){
        color: $color-brand;
    }
}
p{
    a{
        color: $color-brand;
        @include attention(){
            color: darken($color-brand, 10%);
        }
    }
}