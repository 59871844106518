/* ==========================================================================
   #PAGE-FOOT
   ========================================================================== */

.page-foot {
    color: black;
    position: relative;
    bottom: 0px;
    width: 100%;

    @include font-size(24px, 28px);
    background-color: white;
    @include mq($from: tablet){
        min-height: auto;
        text-align: center;
        padding-top: 0;
        padding-bottom: 0;
    }

    &__icons {
        &--desktop {
            padding: 1rem;
            display: block;
            justify-content: center;
            grid-template-columns: 30% 30% 30%;

            @include mq($from: tablet) {
                display: flex;
                padding: 2rem 1rem;
                gap: 2rem;
            }
            @include mq($from: desktop-large) {
                padding: 2rem 0;
            }
        }
    
    }

&__tagline {

    text-align: left;
    font-size: 1.3rem;
    @include mq($from: desktop) {
     width: 50%;
    }
}

&__contactinfo {
    display: block;
    text-align: left;
    font-size: 1.1rem;

    &-address {
        margin-top: 0;
        margin-bottom: 0;
    }
    &-company {
        margin-bottom: 0;
    }

    &-socialmedia {
        display: flex;
        margin-top: 1rem;
        gap: 10px;

        img{
            width: 40px;
            height: 40px;
        }
    }
}



.privacy-bar{
    background-color: #F6F6F6;
    text-align: left;
    @include font-size(13px);
    padding: 27px 39px 22px;
    a{
        color: $color-brand;
        font-family: 'blogger-medium';
        &:hover{
            color: darken($color-brand, 5%);
        }
    }
}



    .page-foot__logo{
        figure {
            margin: 0;
            display: flex;
            width: 100%;
            justify-content: center;

            @include mq($from: tablet){
                margin: 20px 0;
                justify-content: start;
            }
            @include mq($from: desktop){
                margin: 0 40px;
            }
        }
    }

}