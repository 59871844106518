/*------------------------------------*\
	NAV
\*------------------------------------*/

/**

https://jonsuh.com/hamburgers/

 */
nav {
    text-align: center;
}

.main-nav {
    font-family: "blogger-medium",sans-serif;
    @include font-size(17px, 52px);
    position: absolute;
    z-index: 1;
    left: 0;
    margin-left: 0;
    top: $page-header-mobile;
    width: 100%;
    background-color: #fff;
    border-bottom: solid 1px $color-ui;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.51);
    padding-top: 20px;
    padding-bottom: 20px;
    display: none;

    @include mq($from: tablet) {
        top: 0;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        background-color: transparent;
        margin: 0 auto!important;
        border: none;
        width: 630px;
        padding-top: 0;
        padding-bottom: 0;
        display: block !important;
        box-shadow: none;
    }
}

.nav {
    list-style: none;
    padding: 0;


}

.nav li {
    float: left;
    margin-left: 0;
    position: relative;
    width: 100%;
    &:hover {
        > a {
            color: #9B9B9B;
        }
    }

    @include mq($from: tablet) {
        width: auto;
        margin-left: 46px;
        &:first-of-type{
            margin-left: 0;
        }
    }

}

.nav a {
    display: block;
    color: black;
    padding: 12px 0;

    transition: none;

    @include mq($until: tablet) {
        text-align: center;
    }
    @include mq($from: tablet, $until: desktop-large) {
        padding-top: 0;
        line-height: 40px;
    }

    @include attention() {
        color: $color-brand;
    }
    &.active {
        color: $color-brand;
    }
}

.nav > li > a {
    &.has-dropdown {
        @include mq($from: tablet) {
            padding-right: 20px;
            background: url("/nvvr/img/icon-arrow-down.svg") no-repeat center right;
            background-size: 14px;
        }
    }
}

/*--- DROPDOWN ---*/
.nav ul {
    background: #fff;
    list-style: none;
    position: absolute;
    left: -9999px;
    border: solid 1px $color-ui;
    padding: 10px 0 20px 0;
    margin: 0;
    min-width: 220px;
    @include font-size(16px, 20px);
}

.nav ul li {
    float: none;
    margin-left: 0;
}

.nav ul a {
    white-space: nowrap;
    padding: 10px 10px 10px 20px;
    color: $color-brand;
}

@include mq($from: tablet) {
    .nav li:hover ul {
        left: 0;
    }
}

.nav li:hover ul a {
    text-decoration: none;
}

.nav li:hover ul li a:hover {
    background: lighten($color-ui, 10%);
}

.language-select {
    text-align: center;
    text-transform: uppercase;
    @include font-size(13px);
    display: block;
    padding: 10px 0;
    @include mq($from: tablet) {
        position: absolute;
        top: -61px;
        right: 180px;
    }

    @include mq($from: 1000px, $until: 1024px) {
        right: 190px;
    }
}




.categories{
    text-align: left;
    a{
        color: white;
        font-family: blogger-regular;
    }
}
.category{
    display: inline-block;
    margin-right: 30px;
    margin-bottom: 15px;
    @include font-size(20px);

    border: 1px solid white;
    border-radius: 21px;
    padding: 7px 21px 6px;

    &:hover{
        background-color: white;
        color: $color-brand;
        a{
            color: $color-brand;
        }
        transition: 300ms;
    }
    &--active{
        background-color: white;
        color: $color-brand;

    }
}


.error-nav {
    @include mq($from: tablet){
        max-width: 520px;
        margin: 0 auto;
    }
    }