///* ========================================================================
//   #WRAPPER
//   ======================================================================== */

.o-wrapper {
    margin: 0 auto;
    max-width: $page-width;
    position: relative;
    @include mq($until: tablet) {
        padding-left: $half-spacing-unit;
        padding-right: $half-spacing-unit;
    }

        padding-right: 24px;
        padding-left: 24px;

}

.o-wrapper--half {

    padding-left: 0;
    padding-right: 0;

    @include mq($from: tablet) {
        padding-left: 15%;
        padding-right: 15%;
    }

    @include mq($from: desktop) {
        padding-left: 20%;
        padding-right: 20%;
    }
}
