/* ==========================================================================
   #FORMS
   ========================================================================== */

.form{
  input{
    background-color: white;
    border: none;
    border-bottom:1px solid #D9D9D9;
    width: 100%;
    @include mq($from: tablet){
      width: 40%;
    }

  }
  textarea{
    background-image: -webkit-linear-gradient(to left, white 0px, transparent 10px), -webkit-linear-gradient(to right, white 0px, transparent 10px), -webkit-linear-gradient(white 30px, #ccc 30px, #ccc 31px, white 31px);
    background-image: -moz-linear-gradient(to left, white 0px, transparent 10px), -moz-linear-gradient(to right, white 0px, transparent 10px), -moz-linear-gradient(white 30px, #ccc 30px, #ccc 31px, white 31px);
    background-image: -ms-linear-gradient(to left, white 0px, transparent 10px), -ms-linear-gradient(to right, white 0px, transparent 10px), -ms-linear-gradient(white 30px, #ccc 30px, #ccc 31px, white 31px);
    background-image: -o-linear-gradient(to left, white 0px, transparent 10px), -o-linear-gradient(to right, white 0px, transparent 10px), -o-linear-gradient(white 30px, #ccc 30px, #ccc 31px, white 31px);
    background-image: linear-gradient(to left, white 0px, transparent 10px), linear-gradient(to right, white 0px, transparent 10px), linear-gradient(white 30px, #ccc 30px, #ccc 31px, white 31px);
    background-size: 100% 100%, 100% 100%, 100% 31px;
    border: none;
    -webkit-appearance: none;
    width: 100%;
    @include mq($from: tablet){
      width: 70%;
    }
    line-height: 31px;


  }
  .form-group{
    margin-bottom: 24px;

  }
}

.alert{
  font-family: 'blogger-bold', sans-serif;
  &-alert-success{
    color: green;
  }
  &-danger {
    color: red;
  }
}

#fieldEmail{
   padding: 11px 15px 9px;
  border: none;
  font-family: "blogger-regular";
  border-radius: 20px;
  margin-bottom: 9px;
}