/* ==========================================================================
   #LIST
   ========================================================================== */


.c-list {
    list-style: none;
    margin: 0 ;
    padding: 0;
}

.c-list--bordered {
    margin-top: $base-spacing-unit;
    border-top: solid 1px $color-ui;

    > li {
        border-bottom: solid 1px $color-ui;
        margin-top: 10px;
        padding-left: $half-spacing-unit;
        padding-right: $half-spacing-unit;
    }
}



